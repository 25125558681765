<template>
  <div class="main-header f-sb-c">
    <div class="info f-fs-s">
      <el-image
        class="rotate avatar"
        :src="user.avatar || require('@/assets/img/default/avatar.gif')"
        fit="contain"
      />

      <div class="my f-c-fs-c">
        <div class="nickname">{{ user.nickname }}</div>
        <div>
          <el-button @click="loginOut" size="mini" type="primary"
            >退出</el-button
          >
        </div>
      </div>
    </div>
    <!-- <h1 class="title">后台管理中心</h1> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {},
  mounted() {},
  methods: {
    // toPersonalCenter() {
    //   this.$router.push("/personalCenter");
    // },
    loginOut() {
      this.$store.replaceState(Object.assign({}, this.$store.state, {}));
      this.$store.commit('save', {
        path: 'currMenuPaths',
        data: ['店铺管理', '店铺信息'],
      });
      localStorage.clear();
      this.$router.push('/login');
      this.$message.success('退出登录成功！');
    },
  },
};
</script>
<style lang="scss" scoped>
.main-header {
  height: 70px;
  padding: 0 20px;
  background: #333333;
}
.info {
  .avatar {
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .nickname {
    cursor: pointer;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.title {
  color: #ff5000;
  margin-left: 30px;
}
</style>
