<template>
  <div>
    <div v-for="(item, ind) in menus" :key="ind">
      <el-submenu :index="item.id" v-if="item.children">
        <template slot="title">
          <i class="el-icon-caret-bottom"></i>
          <span>{{ item.name }}</span>
        </template>
        <tree-sub-menu :menus="item.children"></tree-sub-menu>
      </el-submenu>
      <template v-else>
        <el-menu-item :index="item.url">
          <div slot="title">
            {{ item.name }}
            <el-tag class="pop" v-if="item.id === '32' && refundNum !== 0">
              <!-- v-if="item.name === '退款订单' && refundNum !== 0" -->
              {{ refundNum }}
            </el-tag>
            <el-tag class="pop" v-if="item.id === '31' && waitSend !== 0">
              <!-- v-if="item.name === '全部订单' && waitSend !== 0" -->
              {{ waitSend }}
            </el-tag>
            <el-tag class="pop" v-if="item.id === '33' && waitReceived !== 0">
              <!-- v-if="item.name === '进货订单' && waitReceived !== 0" -->
              {{ waitReceived }}
            </el-tag>
          </div>
        </el-menu-item>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "treeSubMenu",
  props: {
    menus: {
      type: Array,
      required: true,
    },
    refundNum: {},
    waitSend: {},
    waitReceived: {},
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
i {
  color: #fff;
}
.pop {
  border-radius: 50%;
  background: #f40;
  color: #fff;
}
</style>
